.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: large;
    font-weight: 500;
    color: rgb(255, 255, 255);
    background-image: linear-gradient(to right, rgba(255, 238, 0, 0.555), rgb(188, 188, 188));
    background-color: white;
    margin: 0;
    border-bottom: 2px solid rgb(16, 0, 107);
    padding: 1rem 2rem 1rem 2rem;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 150;
}

.sandwich-menu-container {
    padding: 0 0 0 30px;
}

.sandwich-menu {
    width: 35px;
    height: 5px;
    background-color: black;
    margin: 6px 5px;
  }

.container{
    position:relative;
    display: flex;  
    width:100%;
    flex-direction: column;
  }

  .menu_container{
    position:fixed;
    width:100%;
    height:100vh;
    background-image: linear-gradient(to bottom, rgb(253, 240, 66), rgb(143, 143, 143));
    color:#fff;
    flex-direction: column;
    z-index: 200;
    text-align: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }


ul {
    list-style: none;
}

li {
    margin: 20px 0;
}

.nav-number-mobile {
    display: none;
}





.nav-links {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    height: 90vh;
    font-size: xx-large;
    font-weight: 900;
    padding-top: 7rem;
    font-weight: 600;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}




.close-btn-div {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 10vh;
    
}

.close-btn-div button {
    margin-right: 30px;
    border: none;
    color: white;
    background-color: transparent;
    cursor: pointer;
    font-size: xx-large;
}



a:link {
    text-decoration: none;
    color: rgb(0, 0, 0);
}

span a:link {
    word-break: keep-all;
    margin: 0;
    padding: 0;
    color: rgb(0, 21, 255);
    font-size: xx-large;

}






a:visited {
    color: rgb(0, 0, 0);
}




@media only screen and (max-width: 700px) {
    span a:link {
        font-size: x-large;
    }
    .nav-links {
        justify-content: space-evenly;
        margin: 0;
        font-size: x-large;
    }
    
    
    
}



