
.gallery-container {
    padding: 14vh 1rem 3rem 1rem;
    display: block;
    margin: 0 auto;
    text-align: center;
}
.gallery-container h1 {
    margin: 2rem 0;
}

.gallery-dents {
    display: grid;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 15px;
    background-color: rgb(235, 235, 235);
    border-radius: 15px;
    padding: 1rem 15px;
}


.gallery-video-wrapper {
    text-align: center;
    padding: 0;
    display: block;
    margin: 0 auto;
    text-align: center;
    z-index: 1;
    overflow: hidden;
    margin-top: 10px;
    
    width: 100%;
}




 
.gallery-video-wrapper video {
    overflow: hidden;
    margin: 0 auto;
    width: 47%;
    margin-bottom: 25px;
    z-index: 1;
    padding: 0 10px 0 10px;
}



@media only screen and  (max-width: 700px) {
    .gallery-video-wrapper {
        display: block;
    }
    .gallery-video-wrapper video {
        width: 100%;
    }
    .gallery-container{
        padding: 14vh 5px 3rem 5px;
    }
    .gallery-dents{
        padding: 0.7rem 5px;
    }
}

@media only screen and  (max-width: 1000px) {
    .gallery-video-wrapper video {
        max-width: 100%;
    }
}

