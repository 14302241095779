.guarantee-container {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 0 5px 4rem 5px;
}

.guarantee-heading {
    border: none;
    font-weight: 600;
    color: rgb(0, 0, 0);
    margin: 0 auto;
    word-break: keep-all;
    padding: 1rem 0 1rem 0;
    border-top: 2px solid rgb(236, 236, 236);
    width: 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.555), rgba(255, 238, 0, 0.242), rgba(255, 255, 255, 0.555));
    font-size: 5VW;
    font-family: Georgia, 'Times New Roman', Times, serif;
    letter-spacing: 1.5vw;
}

.guarantee-text-div {
    width: 80%;
    margin: 5px auto;
    padding: 1rem 7px 3rem 7px;
    transition: all 3s ease-in-out;
    animation: 3s;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
}

.guarantee-text-div p {
    font-size: large;
    padding-top: 1rem;
}


@media only screen and (max-width: 1000px) {
    .guarantee-text-div {
        width: 94%;
        padding: 1rem 0px 0 0px;
    }
   
}


